import React from "react";

const Header = () => {
  return (
    <div className="mb-8 px-6 flex flex-col items-center">
      <h1 className="max-w-3xl bg-gradient-to-br from-white to-gray-400 bg-clip-text text-center text-4xl font-medium leading-tight text-transparent sm:text-4xl md:text-5xl font-poppins">
        Full-stack software services for fast-paced teams.
      </h1>
      <h2 className="my-6 max-w-xl text-center text-white text-base leading-relaxed md:text-lg font-light font-poppins">
        From features to complete products, we help you ship your ideas with
        quality + speed.
      </h2>
    </div>
  );
};

export default Header;
