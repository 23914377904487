import React, { useEffect } from "react";
import Header from "./components/Header/Header";
import logo from "./assets/images/logo.png"; // Assuming your logo is in the src folder
import {
  motion,
  useMotionTemplate,
  useMotionValue,
  animate,
} from "framer-motion";
import Ticker from "./components/Ticker/Ticker";
import { FiArrowRight } from "react-icons/fi";
import { Stars } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";

const App = () => {
  const COLORS = ["#13FFAA", "#1E67C6", "#CE84CF", "#DD335C"];

  const color = useMotionValue(COLORS[0]);
  const backgroundImage = useMotionTemplate`radial-gradient(125% 125% at 50% 0%, #020617 50%, ${color})`;
  const border = useMotionTemplate`1px solid ${color}`;
  const boxShadow = useMotionTemplate`0px 4px 24px ${color}`;

  useEffect(() => {
    animate(color, COLORS, {
      ease: "easeInOut",
      duration: 8,
      repeat: Infinity,
      repeatType: "mirror",
    });
  });

  return (
    <motion.section
      className="flex flex-col justify-center h-screen w-screen text-center"
      style={{
        backgroundImage,
      }}
    >
      <div
        className="absolute top-4 right-4 text-white text-sm px-2 py-1 rounded-full flex items-center gap-2 font-poppins font-light"
        style={{ backgroundColor: "rgba(200, 200, 200, 0.15)" }}
      >
        {/* Green Dot Icon */}
        <span
          className="w-2.5 h-2.5 rounded-full"
          style={{
            backgroundColor: "#13FFAA",
          }}
        ></span>
        <span>Beta</span>
      </div>

      <div className="relative z-10 flex flex-1 flex-col items-center justify-center">
        {/* <img src={logo} className="w-36 h-auto" alt="Logo" /> */}
        <img src={logo} className="w-28 h-auto sm:w-28 md:w-36" alt="Logo" />
      </div>
      <div className="relative z-10 flex flex-1 flex-col items-center justify-center px-4 py-2 sm:px-6 sm:py-3">
        <Header />
        <div className="flex gap-4 mt-5 mb-8 md:mb-16">
          <a
            href="http://calendly.com/jumpshotlabs/intro-call" // Replace with your link
            target="_blank"
            rel="noopener noreferrer"
          >
            <motion.button
              whileHover={{ scale: 1.015 }}
              whileTap={{ scale: 0.985 }}
              className="group relative font-poppins text-sm sm:text-base px-4 sm:px-6 py-2 sm:py-3 cursor-pointer flex items-center gap-1.5 rounded-full bg-gray-950/10 text-gray-50 transition-colors duration-80 ease-in-out"
              style={{
                border,
                boxShadow,
              }}
            >
              Schedule a call
              <FiArrowRight className="transition-transform group-hover:-rotate-45 group-active:-rotate-12" />
            </motion.button>
          </a>
        </div>
      </div>
      {/* <Ticker /> */}
      <div className="absolute inset-0 z-0">
        <Canvas>
          <Stars radius={50} count={1000} factor={4} fade speed={1} />
        </Canvas>
      </div>
    </motion.section>
  );
};

export default App;
